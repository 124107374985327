@tailwind base;
@tailwind components;
@tailwind utilities;

.trans {
  @apply transition duration-300 ease-in-out;
}

/* ---- reset ---- */
canvas {
  display: block;
}
/* ---- particles.js container ---- */

#particles-js {
  height: 100%;
  width: 100%;
  /* background: #201E28; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 10; */
}
#particles-js canvas {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
  transition: opacity 0.8s ease, transform 1.4s ease;
}
